import React, {useState, useReducer, useEffect} from 'react';

import './login.css';

import Aos from "aos";
import "aos/dist/aos.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons'; // Checklist
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'; // Comment
import { faHandshakeSimple } from '@fortawesome/free-solid-svg-icons'; // Gratitude
import { faTools } from '@fortawesome/free-solid-svg-icons'; // Tools
import { faShareSquare } from '@fortawesome/free-solid-svg-icons'; // Share Launch
import { faAd } from '@fortawesome/free-solid-svg-icons'; // 100 Places
import { faLink } from '@fortawesome/free-solid-svg-icons'; // Useful Resouces
import { faGift } from '@fortawesome/free-solid-svg-icons'; // Bonus
import { faClock } from '@fortawesome/free-solid-svg-icons'; // Clock
import { faTrophy } from '@fortawesome/free-solid-svg-icons'; 
import { faRocket } from '@fortawesome/free-solid-svg-icons'; 
import { faBolt } from '@fortawesome/free-solid-svg-icons'; 
import { faDollar } from '@fortawesome/free-solid-svg-icons'; 
import { faRobot } from '@fortawesome/free-solid-svg-icons'; 
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'; 





const preview = require("./imgs/preview.webm")
const preview_gif = require("./imgs/preview.webm")
const promo = require("./imgs/promo.png")
const checklist = require("./imgs/checklist.png")
const tools = require("./imgs/tools.png")
const upvotes = require("./imgs/upvotes.png")
const places = require("./imgs/places.png")
const comment = require("./imgs/comment.png")
const replies = require("./imgs/gratitude_replies.png")
const logo = require("./imgs/logo.png")
const lc = require("./imgs/lc.png")
const gpt = require("./imgs/gpt.png")


function LoginPage(props: any) {
  const [loginForm, showLoginForm] = useState(false)
  const [display, setDisplay] = useState(["none", "none", "none", "none", "none"])
  const [rotate, setRotate] = useState(["rotate90", "rotate90", "rotate90",  "rotate90", "rotate90"])
  const [email, setEmail] = useState("")
  const [commentTemplate, setCommentTemplate] = useState("rotate90")
  const [displayCommentTemplate, setDisplayCommentTemplate] = useState("none")
  const [displayGratitudeTemplate, setDisplayGratitudeTemplate] = useState("none")
  const [gratitudeTemplate, setGratitudeTemplate] = useState("rotate90")

 

  
   function checkEmail(e: any) {
       let modEmail: string = email.replace("@", "%40")
       window.open("https://store.payproglobal.com/checkout?products[1][id]=88259&billing-email="+modEmail, '_blank')!.focus();
     
   }
   
   
    let loginInfoButtons: any = <div className="login-info-buttons">
            <div className="login-info-button lib1" onClick={(e: any)=>{
                const myAbsolutelyNotNullElement = window.document.getElementById("buy-offer")!
                myAbsolutelyNotNullElement.scrollIntoView()}
            }>Buy</div>
            <div className="login-info-button lib2" onClick={(e: any)=>{
                const myAbsolutelyNotNullElement = window.document.getElementById("login-main-block")!
                myAbsolutelyNotNullElement.scrollIntoView()}
            }>Show Me</div>
        </div>
     let previewType: any =    <video className="preview-video" controls>
          <source src={preview} type="video/mp4"/>
       
        Your browser does not support the video tag.
        </video>
        //if ( window.innerWidth <= 760){
          //previewType = <img className="preview-video" src={preview_gif}/>
        //}  
    
  return (
    <div className="login-container">
    <div className="login-header">

  <div className="descr-container">
   <div className="login-description">
      <div className="ld1"><img className="preview-logo" src={logo}/> Founder Growth Space</div>
      <div className="ld2">Every Template You'll Ever Need to Grow Your Startup</div>
      {loginInfoButtons}
      <a href="https://www.producthunt.com/posts/founder-growth-space?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-founder&#0045;growth&#0045;space" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=430997&theme=light" alt="Founder&#0032;Growth&#0032;Space - Every&#0032;Template&#0032;You&#0039;ll&#0032;Ever&#0032;Need&#0032;to&#0032;Grow&#0032;Your&#0032;Startup | Product Hunt" style={{width: "250px", height: "54px"}} /></a>

    </div>

    </div>
     </div>


   <div className="first-promo" id="first-promo">
       <div className="fp-h">The Only Growth Kit You'll Ever Need</div>
       <div className="fp-line"></div>

       <div className="fp-item-three">
       <div className="fp-item">
         <div className="fp-line-mobile"></div>
       <FontAwesomeIcon className="fp-icon" icon={faClock}/>
         Save 200+ hours on research
       </div>

        <div className="fp-item">
          <div className="fp-line-mobile"></div>
       <FontAwesomeIcon className="fp-icon" icon={faTrophy}/>
         Attract More Customers
       </div>

        <div className="fp-item">
          <div className="fp-line-mobile"></div>
       <FontAwesomeIcon className="fp-icon" icon={faBolt}/>
         Get More Traffic
       </div>
        </div>
       <div className="fp-line"></div>

       <div className="fp-item-three">
       <div className="fp-line-mobile"></div>
         <div className="fp-item">
       <FontAwesomeIcon className="fp-icon" icon={faDollar}/>
         Make More Money
       </div>
       <div className="fp-line-mobile"></div>
         <div className="fp-item">
       <FontAwesomeIcon className="fp-icon" icon={faRocket}/>
         400+ Templates, Tips and Resources
       </div>
       <div className="fp-line-mobile"></div>
         <div className="fp-item">
       <FontAwesomeIcon className="fp-icon" icon={faGift}/>
         Bonus: Fundraising Kit
       </div>
   </div>
   </div>
 

    <div className="login-main" id="login-main-block">
    <div className="main-h" >
        What's included?
      </div>
      <div className="login-line"></div>
  
     
    <div className="first-login-block">
      <div className="login-h">
        
        <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faListCheck} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Notion Startup CRM</div>
       <div className="login-main-descr">
        Notion templates, designed specifically to address your business needs in Promotion, Sales, Finances, Managing Team, Organizing and Planning. 
      </div>
      </div>
      <div className="image-container">
       <img style={{width: "800px"}} className="promo-img1" src={checklist}/>
       <div className="dotted-grid dotted-grid-right"></div>

      </div>    
      <img style={{width: "800px"}} className="promo-img-mobile" src={checklist}/>
      </div>    


       <div className="login-block">
 
     
         <div className="image-container">
       <img className="comments-example" src={comment}/>
       <div className="dotted-grid dotted-grid-left-comment"></div>

      </div> 

 
      <div className="login-h">
      <div className="promo-heading"> <FontAwesomeIcon className="promo-icon" icon={faCommentDots} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Growth Checklist, Guides and Tips</div>
       <div className="login-main-descr">
       Learn and Implement! Best Articles, Tips and Guides on Marketing Strategies from all over the internet. 
      Organically, paid, using social media, product features or content. Just choose your type and grow audince by a lot!
      </div>
      </div>
 
      <img style={{width: "800px"}} className="promo-img-mobile" src={comment}/>
 
</div> 

<div className="login-block">

      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faTools} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Newsletter Sponsorship Resources</div>
        <div className="login-main-descr">
      Explore 50+ newsletters ready to promote your product.
      </div>
      </div>
     
       
        <div className="image-container">
       <img className="promo-img2" src={tools}/>  
       <div className="dotted-grid dotted-grid-right"></div>

      </div> 
      <img style={{width: "800px"}} className="promo-img-mobile" src={tools}/>
      </div>
     
      <div className="login-block">
       <div className="image-container">
      <img className="promo-img3" src={replies}/>
       <div className="dotted-grid dotted-grid-left"></div>

      </div> 
      <div className="login-h">
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faHandshakeSimple} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Product Hunt Kit</div>
       <div className="login-main-descr">
       Launch Checklist, 100 Product Hunt Maker Comment Templates, Tools, Guides, Places to Share Launch
      </div>


      </div>
   <img style={{width: "800px"}} className="promo-img-mobile" src={replies}/>
      </div>


      <div className="login-block">

      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faAd} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>100 Free Places To Promote Product</div>
        <div className="login-main-descr">
      List of Places Where You Can Promote Your Startup for Free
      </div>
      </div>
     
       
        <div className="image-container">
       <img className="promo-img4" src={places}/>
       <div className="dotted-grid dotted-grid-right"></div>

      </div> 

      <img style={{width: "800px"}} className="promo-img-mobile" src={places}/>
      </div>
       <div className="login-block">
      <div>

        <div className="image-container">
       <img className="promo-img5" style={{maxWidth: "350px", height: "380px"}} src={upvotes}/>
       <div className="dotted-grid dotted-grid-left" style={{right: "65%"}} ></div>

      </div> 

       </div>
      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faShareSquare} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Growth Tools</div>
        <div className="login-main-descr">
      More than best 100 tools in 10 categories will help you grow and manage you startup effectively.
      </div>
      </div>
     
       <img style={{width: "800px"}} className="promo-img-mobile" src={upvotes}/>
      </div>
  
  <div className="login-block">

      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faRobot} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>ChatGPT Marketing Prompts</div>
        <div className="login-main-descr">
        More than 70+ Marketing Prompts for ChatGPT to Boost Your Growth
       </div>
      </div>
     
       
        <div className="image-container">
       <img className="promo-img4" src={gpt}/>
       <div className="dotted-grid dotted-grid-right"></div>

      </div> 

      <img style={{width: "800px"}} className="promo-img-mobile" src={gpt}/>
      </div>


      <div className="login-block">
      <div>

        <div className="image-container">
       <img className="promo-img4" src={lc}/>
       <div className="dotted-grid dotted-grid-left" style={{right: "75%"}} ></div>

      </div> 

       </div>
      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faSquareCheck} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Don't Know Where to Start?</div>
        <div className="login-main-descr">
      It's not a problem! We have checklist specifically for this kit!
      </div>
      </div>
     
       <img style={{width: "800px"}} className="promo-img-mobile" src={lc}/>
      </div>


      <div className="login-block-no-image">

      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faLink} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Useful Resources</div>
        <div className="login-main-descr">
      25 LinkedIn and Email Templates, "Thank you" Replies
      </div>
      </div>
     
       <div>
     
       </div>
      </div>

       <div className="login-block-no-image">

      <div className="login-h" style={{width: "30rem"}}>
       <div className="promo-heading"><FontAwesomeIcon className="promo-icon" icon={faGift} style={{color: "rgb(24,20,20)", fontSize: "40px"}}/>Bonus</div>
        <div className="login-main-descr">
     Fundraising Checklist and Databases
      </div>
      </div>
     </div>
       <div>
     
       </div>
 
 
      </div>
      <div className="preview" id="preview">
       <div className="main-h">
       Preview
      </div>
        {previewType}
       </div>

      
      <div className="faq-main-block">
       <div className="main-h">
       F.A.Q.
      </div>
      <div className="faq-block">

      
     <div  className="faq-head">How Are We Different From Other Kits?</div>
     
      <div className="showAns" >
	We analyzed all other kits and all of them either outdated either don't have enough resources. Unlike other kits resources in Founder Growth Space are up-to-date and it offers several kits (like Product Hunt Kit and Notion Startup CRM) inside. You are unlikely to find such a category-diverse kit with so many resources. Founder Growth Space is based on our previous product <a href="https://www.producthunt.com/products/growth-hacking-kit">Growth Hacking Kit</a>.      
       </div>
      
      </div>

       <div className="faq-block">


       <div className="faq-head">Is it really worty 29$?</div>
      
       
      
     
      <div className="showAns">
        Doing all this research yourself will take 200+ hours. To save your effort and time we have analyzed and collected best tips, templates, resources, tools and checklists.
      </div>
      
      </div>

       

 <div className="faq-block">

       <div className="faq-head">What I will receive after purchase? </div>
      
       
      
     
      <div className="showAns" >
        You will receive link to Notion base, you need to duplicate this base. You don't need premium Notion account.
      </div>
    </div>

      

       <div className="faq-block">

      
      <div className="faq-head">Any other questions? 
      
       
      </div> 
     
      <div className="showAns" >
        Contact us via Telegram <a style={{color: "black", textDecoration:"none"}} href="https://t.me/maksraiz">@maksraiz</a> or via Twitter <a style={{color: "black", textDecoration:"none"}} href="https://twitter.com/maksraiz">@maksraiz</a>.

      </div>
      
      </div>

 </div>

    <div className="login-block">

    <div className="login-sell">

    <div className="login-offer" id="buy-offer">
    <div className="offer-heading" style={{color: "white", fontSize: "45px", textAlign: "center",backgroundColor: "rgba(12,24,46,255)", width: "384px", paddingTop:"10px",paddingBottom: "10px",  borderTopLeftRadius: "25px", borderTopRightRadius: "25px"}}>Early price</div>
    <div className="offer-main">
      <div className="price-of-pack" style={{fontSize: "40px", marginTop: "5px"}}>29$ <span style={{textDecoration: "line-through"}}>69$</span></div>
      <div className="price-of-pack plus-vat" style={{fontSize: "15px", color: "grey"}}>+ VAT</div>
      <div className="login-features-list">
        <span>&#x02713;</span>  100+ Places to Post your Startup<br/>
        <span>&#x02713;</span> 50+ Newsletter Sponsorships<br/>
        <span>&#x02713;</span> 40+ Communities for Founders<br/>
        <span>&#x02713;</span> ProductHunt Kit<br/>
        <span>&#x02713;</span> 25 Cold Email and LinkedIn Templates<br/>
        <span>&#x02713;</span> 100+ Tools<br/>
        <span>&#x02713;</span> 70+ Growth Guides, Checklists and Tips<br/>
<span>&#x02713;</span> Notion Startup CRM<br/>
        <span>&#x02713;</span> 70+ ChatGPT Marketing Prompts<br/>
        <span>&#x02713;</span> Bonus: Fundraising Checklist and Databases<br/>
      </div>
      <input type="text" onChange={(e: any)=>{setEmail(e.target.value)}} className="login-email" placeholder={"Email"}/>
      <div onClick={checkEmail} className="buy-button">Buy Lifetime</div>


      <div style={{marginBottom: "50px"}}>Secure via PayPro Global</div>
       </div>

    </div>
    </div>
    </div>
  
    
   <div className="login-footer">
      © 2024 If you didn't receive your order please check spam folder or you can contact us via Telegram <a style={{color: "white", textDecoration:"none"}} href="https://t.me/maksraiz">@maksraiz</a>, via Twitter <a style={{color: "white", textDecoration:"none"}} href="https://twitter.com/maksraiz">@maksraiz</a> or via email max@foundergrowth.space.
    </div>
    </div>
  );
}

export default LoginPage;




      
